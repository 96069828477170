/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components), {QuinStreet} = _components;
  if (!QuinStreet) _missingMdxReference("QuinStreet", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "There are numerous car insurance secrets that can help save you 25% or more on your insurance premiums if you know what to ask."), "\n", React.createElement(_components.p, null, "Do you have a perfect driving record with no accidents? Are you currently or formerly a member of the military? If you are, and you haven't asked your insurance company for a discount, you could be leaving money on the table."), "\n", React.createElement(_components.h3, null, "Here is how to do it:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Enter your zip code. "), "\n", React.createElement(_components.li, null, "Fill out some basic information to review quotes from the top companies in your area."), "\n", React.createElement(_components.li, null, "Choose the best quote."), "\n", React.createElement(_components.li, null, "Save money."), "\n"), "\n", React.createElement(QuinStreet, {
    ui: "zip"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
