/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "When it comes to auto insurance discounts, you'll find a plethora of potential savings that might apply to your account. In addition to discounts for being a good student, insuring more than one car, or bundling multiple types of policies, you can also save money by not having any violations and DUIs on your driving record, ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "#qs-anchor"
  }, "it's possible you already qualify")), ". If you're eligible for multiple promotions, you stand to save a whole lot more.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
