/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {L1, Default, Variant} = _components;
  if (!Default) _missingMdxReference("Default", true);
  if (!L1) _missingMdxReference("L1", true);
  if (!Variant) _missingMdxReference("Variant", true);
  return React.createElement(L1, null, React.createElement(Default, null, React.createElement(_components.p, null, "Since Matthew Thompson got his very first car, he noticed how expensive car insurance could be. And as each year passed, he noticed that his rates were pretty stagnant. Matthew assumed this was how car insurance worked, with the price either staying the same or going up every year. But there was a secret that Matthew didn’t know that, once he discovered it, saved him a ton of money on his car insurance premiums each month.")), React.createElement(Variant, {
    id: "vanessa"
  }, React.createElement(_components.p, null, "Since Vanessa Ruiz got her very first car, she noticed how expensive car insurance could be. And as each year passed, she noticed that her rates were pretty stagnant. Vanessa assumed this was how car insurance worked, with the price either staying the same or going up every year. But there was a secret that Vanessa didn’t know that, once she discovered it, saved her a ton of money on her car insurance premiums each month.")), React.createElement(Variant, {
    id: "vanessa-2"
  }, React.createElement(_components.p, null, "Since Vanessa Ruiz got her very first car, she noticed how expensive car insurance could be. And as each year passed, she noticed that her rates were pretty stagnant. Vanessa assumed this was how car insurance worked, with the price either staying the same or going up every year. But there was a secret that Vanessa didn’t know that, once she discovered it, saved her a ton of money on her car insurance premiums each month.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
