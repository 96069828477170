/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components), {ALink} = _components;
  if (!ALink) _missingMdxReference("ALink", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "When was the last time you really thought about your family’s future? Did you think about what might happen after you’re gone? Have you thought about who will pay the mortgage, kids education or even put food on the table? That’s where ", React.createElement(_components.strong, null, "Life Insurance"), " comes in, for a low monthly price, you can protect your family in case the worst happens."), "\n", React.createElement(_components.p, null, "Fabric stands out as one of our top ranked Life Insurance Companies for ages below 55.", React.createElement("br"), "\n", React.createElement(_components.strong, null, React.createElement(ALink, {
    to: "fabric-term-life-coverage-page"
  }, "Click here"), " to get a quote before the rates go up.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
