/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    img: "img",
    h5: "h5",
    em: "em",
    strong: "strong",
    h3: "h3",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components), {ALink, L2, Default, QuinStreet, Variant} = _components;
  if (!ALink) _missingMdxReference("ALink", true);
  if (!Default) _missingMdxReference("Default", true);
  if (!L2) _missingMdxReference("L2", true);
  if (!QuinStreet) _missingMdxReference("QuinStreet", true);
  if (!Variant) _missingMdxReference("Variant", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Arizonians, before you make your next car insurance payment see how you can save hundreds of dollars. Are you living in a qualified zip code and drive less than 50 miles a day? And are you currently insured?"), "\n", React.createElement(ALink, {
    to: "lior-test-telephone",
    type: "button"
  }, "call me"), "\n", React.createElement("p", null, React.createElement("a", {
    href: "tel:1844-845-7624"
  }, "1844-845-7624")), "\n", React.createElement("p", null, React.createElement("a", {
    href: "tel:1844-845-7624"
  }, "Call Now")), "\n", React.createElement(_components.p, null, "There are many discounts that insurance companies won’t tell you about and it just takes 5 minutes to get a better quote."), "\n", React.createElement(_components.img, {
    src: "../images/iStock-531931244.jpg",
    alt: ""
  }), "\n", React.createElement(_components.h5, null, "AS SEEN ON"), "\n", React.createElement(_components.img, {
    src: "../images/media-logo-no-msn.png",
    alt: ""
  }), "\n", React.createElement(_components.p, null, "Carol had a perfect driving record and drove less than 50 miles a day. She was furious when she found out that she was overpaying for car insurance."), "\n", React.createElement(_components.p, null, "After just a few clicks, Carol is saving hundreds of dollars each year, and she couldn't be happier: “", React.createElement(_components.em, null, React.createElement(_components.strong, null, "Our insurance premium has been cut by more than half!\""))), "\n", React.createElement(_components.p, null, "It's crucial to compare quotes between insurance companies at least once every year – especially now when the price of everything is going up."), "\n", React.createElement(_components.p, null, "In many cases, you can save hundreds of dollars by shopping around and comparing quotes. The process is simple and only takes a few minutes."), "\n", React.createElement(_components.h3, null, "Here is how to do it:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Enter your zip code"), "\n", React.createElement(_components.li, null, "List your age and check relevant boxes"), "\n", React.createElement(_components.li, null, "Get a better quote"), "\n"), "\n", React.createElement(L2, null, React.createElement(Default, null, React.createElement(QuinStreet, {
    variant: "with-venus-fixes"
  })), React.createElement(Variant, {
    id: "whitelabel-v2"
  }, React.createElement(QuinStreet, {
    ui: "whitelabel",
    variant: "v2",
    title: "Explore Top-Rated Insurers Near You!",
    titleComponent: "h3",
    subTitle: "Use the filters below to get the best custom insurance offers for you",
    listingsTitle: "Click on 2 or more insurers below to compare quotes and maximize your savings.",
    buttonText: "Complete questions above",
    activeButtonText: "Get Quotes!"
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
