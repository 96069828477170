/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    img: "img"
  }, _provideComponents(), props.components), {TextBlock} = _components;
  if (!TextBlock) _missingMdxReference("TextBlock", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Before you pay your next car insurance premium you need to see this..."), "\n", React.createElement(_components.p, null, "There is a new smart comparison system that most drivers are not aware of, because of that they are probably overpaying for their car insurance. This system can reduce your premiums significantly."), "\n", React.createElement(TextBlock, {
    slug: "car-insurance-image-link"
  }), "\n", React.createElement(_components.h3, null, "AS SEEN ON"), "\n", React.createElement(_components.img, {
    src: "../images/media-logo-no-msn.png",
    alt: ""
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
