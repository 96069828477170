/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Are you looking for cheap car insurance but worried about sacrificing quality and service in favor of a more affordable rate? The word \"cheap\" might be scary when it comes to an car insurance policy, but it doesn't have to be this way. Cheap car insurance can mean something completely different. It's affordable. It's good for your budget. All while providing you with 24/7 customer service and top-of-the-line insurance for your vehicle.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
