/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    strong: "strong"
  }, _provideComponents(), props.components), {L1, Default, Variant} = _components;
  if (!Default) _missingMdxReference("Default", true);
  if (!L1) _missingMdxReference("L1", true);
  if (!Variant) _missingMdxReference("Variant", true);
  return React.createElement(L1, null, React.createElement(Default, null, React.createElement("span")), React.createElement(Variant, {
    id: "parking-ticket-image-text"
  }, React.createElement(_components.p, null, "There are numerous car insurance secrets no one tells you about unless you know to ask. For example, huge discounts that can help you save 25% or more on your monthly insurance premiums, and ", React.createElement(_components.a, {
    href: "#qs-anchor"
  }, React.createElement(_components.strong, null, "it's possible you already qualify")), "**. If you're eligible for multiple promotions, you stand to save a whole lot more. Some of these discounts are time sensitive, so be sure to check if your eligible as soon as possible. Read below to find out more.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
