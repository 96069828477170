const SITE_URL_OVERRIDE = process.env.GATSBY_SITE_URL_OVERRIDE;

const siteMetadata = {
  site: 'TIB',
  siteUrl: SITE_URL_OVERRIDE || 'https://theinsurancebulletin.com', // No trailing slash allowed!
  image: '/TIB-logo.svg',
  iconPath: 'static/TIB-logo-small.svg',
  title: 'The Insurance Bulletin',
  titleTemplate: '%s · The Insurance Bulletin',
  description: 'The Insurance Bulletin is a content publication website that aims to empower '
    + 'you to make the right choice when it comes to insurance.',
  twitter: {
    link: 'https://x.com/TIB_site/',
    handle: '@TIB_site',
  },
  facebook: {
    link: 'https://facebook.com/The-Insurance-Bulletin-100929388814026/',
    adsAccountId: 553370069783226,
  },
  taboola: {
    pixel: 1398723,
    feed: 'venus-theinsurancebulletin-publisher',
  },
  google: {
    tagManager: 'GTM-5FR9X4Q',
  },
  impact: {
    verificationToken: 1624832810,
  },
};

module.exports = { siteMetadata };
