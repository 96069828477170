const config = {
  auto: {
    src: 668028,
    inventorytype: 'generic-listing-incontent',
    ui: {
      zip: {
        668052: {
          src: 668052,
          inventorytype: 'zipwidget',
          redirect_location: 'https://o1.qnsr.com/cgi/r?;n=203;c=1677090;s=30962;x=7936;f=202106241820390;u=j;z=TIMESTAMP;',
          skip_original_src: true,
        },
        690606: {
          src: 690606,
          inventorytype: 'zipwidget',
          href: 'https://o1.qnsr.com/cgi/r?;n=203;c=1681443;s=32741;x=7936;f=202211011344250;u=j;z=TIMESTAMP;',
          skip_original_src: true,
        },
      },
      whiteLabel: {
        668052: {
          src: 668052,
          href: 'https://o1.qnsr.com/cgi/r?;n=203;c=1677090;s=30962;x=7936;f=202106241820390;u=j;z=TIMESTAMP;',
        },
        690606: {
          src: 690606,
          href: 'https://o1.qnsr.com/cgi/r?;n=203;c=1681443;s=32741;x=7936;f=202211011344250;u=j;z=TIMESTAMP;',
        },
      },
      lightbox: {
        691594: {
          src: 691594,
        },
      },
    },
  },
};

export default config;
