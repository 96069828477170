/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {L1, Default, QuinStreet, Variant} = _components;
  if (!Default) _missingMdxReference("Default", true);
  if (!L1) _missingMdxReference("L1", true);
  if (!QuinStreet) _missingMdxReference("QuinStreet", true);
  if (!Variant) _missingMdxReference("Variant", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "If you're worried that you are overpaying for auto insurance, it's probably because you are. Knowing these 7 signs can help you save a lot of money."), "\n", React.createElement(_components.p, null, "There are numerous car insurance secrets no one tells you about unless you know to ask."), "\n", React.createElement(_components.p, null, "Do you have a perfect driving record with no accidents? Are you currently or formerly a member of the military? If you are, and you haven't asked your insurance company for a discount, you could be leaving money on the table."), "\n", React.createElement(_components.p, null, "These huge discounts can save you 25% or more on your monthly insurance premiums. Do any of these 7 signs describe you?"), "\n", React.createElement(_components.h3, null, "You could be eligible for deep discounts if you:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Are a low-mileage driver"), "\n", React.createElement(_components.li, null, "Have a good driving record with no violations, DUIs or tickets"), "\n", React.createElement(_components.li, null, "Are insuring multiple vehicles with the same company"), "\n", React.createElement(_components.li, null, "Are accident-free"), "\n", React.createElement(_components.li, null, "Have driver and passenger airbags"), "\n", React.createElement(_components.li, null, "Have installed an anti-theft system"), "\n", React.createElement(_components.li, null, "Are active military, a veteran, or in the reserves"), "\n"), "\n", React.createElement(L1, null, React.createElement(Default, null, React.createElement(QuinStreet, {
    type: "auto",
    ui: "zip"
  })), React.createElement(Variant, {
    id: "white-label-v3-leads"
  }, React.createElement(QuinStreet, {
    src: "690606",
    ui: "whitelabel",
    variant: "v3",
    title: "Explore Top-Rated Insurers Near You!",
    titleComponent: "h3",
    subTitle: "Use the filters below to get the best custom insurance offers for you",
    listingsTitle: "Click on 2 or more insurers below to compare quotes and maximize your savings.",
    buttonText: "Complete questions above",
    activeButtonText: "Get Quotes!",
    goal: "leads"
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
