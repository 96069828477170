module.exports = [{
      plugin: require('../node_modules/@taboola/venus-core/plugins/plugin-material-ui/gatsby-browser.tsx'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/@taboola/venus-core/src/layouts/page-layout.jsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":false,"isIconAfterHeader":false,"elements":["h2"],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/@taboola/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/TIB-logo-small.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e61e76d12eb69c594121960a5ca345b8"},
    },{
      plugin: require('../node_modules/@taboola/venus-core/plugins/single-article/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@taboola/venus-core/gatsby-browser.tsx'),
      options: {"plugins":[],"siteMetadata":{"site":"TIB","siteUrl":"https://theinsurancebulletin.com","image":"/TIB-logo.svg","iconPath":"static/TIB-logo-small.svg","title":"The Insurance Bulletin","titleTemplate":"%s · The Insurance Bulletin","description":"The Insurance Bulletin is a content publication website that aims to empower you to make the right choice when it comes to insurance.","twitter":{"link":"https://x.com/TIB_site/","handle":"@TIB_site"},"facebook":{"link":"https://facebook.com/The-Insurance-Bulletin-100929388814026/","adsAccountId":553370069783226},"taboola":{"pixel":1398723,"feed":"venus-theinsurancebulletin-publisher"},"google":{"tagManager":"GTM-5FR9X4Q"},"impact":{"verificationToken":1624832810}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"enableIdentityWidget":false,"htmlTitle":"CMS - The Insurance Bulletin","htmlFavicon":"static/TIB-logo-small.svg","modulePath":"/home/venus_builder/venus-ci/workspace/tib/master/node_modules/@taboola/venus-core/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
