/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "We strive to help you make smarter financial decisions. While we adhere to strict editorial integrity, many or all of the products featured here are from our partners who compensate us. Here’s an ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/how-we-make-money"
  }, "explanation")), " for how we make money.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
