/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components), {ALink} = _components;
  if (!ALink) _missingMdxReference("ALink", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Arizonians, before you pay your next car insurance premium you need to see this..."), "\n", React.createElement(_components.p, null, "There is ", React.createElement(ALink, {
    to: "qs-direct-to-form-auto-insurance"
  }, "a new smart system"), " you are probably unaware of, and the insurance companies will not tell you about it either. Most likely, you are seriously overpaying for your current policy."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
